:root {
  --yellow: #FFC000;
  --orange: #BF5700;
  --slate: #41516C;
  --darkSlate: #1e2532;
  --whiteSmoke: #f0eff0;
  --mediumGray: #999;
}

.box {
  flex:1;
  position: relative;
}

.white-box {
  background-color: var(--whiteSmoke);
}

.row {
  display:flex;
  width:100%;
}

/* all images should be 400px by 400px */
/* https://resizeimage.net/ */
.portfolio-icon {
  width: auto;
  height: 100%;
  max-width: 250px;
  opacity: 1;
  display: block;
  transition: .5s ease;
  backface-visibility: hidden;
  border-radius: 10px;
/*  filter: grayscale(100%);*/
}

.portfolio-title {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  border: 2px solid var(--slate);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 0px;
  color: white;
}

.profile-item-details {
  background-color: var(--slate);
  border-radius: 10px;
  padding-left: 0px;
  padding-right: 0px;
}

#about-me-section-header {
  margin-bottom: 50px;
}

.project-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 7px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: white;
}

.project-details:first-of-type {
  padding-top: 20px;
}

.project-details:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-bottom: 20px;
}

.project-details p:first-child {
  padding-top: 30px;
}

.project-details p:last-child {
  margin-bottom: 0px;
}

.project-details-info {
  margin-bottom: 0px;
}

.portfolio-item-hover-description {
  color: white;
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  width: 75%;
}

.portfolio-item-hover-view-code {
  color: white;
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  text-wrap: nowrap;
  min-width: 36px;
}

.portfolio-item-hover-view-site {
  
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  text-wrap: nowrap;
}

.box-background:hover .portfolio-icon {
  opacity: 0.1;
}

/* TODO: React doesn't like it when I combine these classes
         using comma separation.
*/
.box-background:hover .portfolio-item-hover-description {
  opacity: 1;
}

.box-background:hover .portfolio-item-hover-view-code {
  opacity: 1;
}

.box-background:hover .portfolio-item-hover-view-site {
  opacity: 1;
}

.text {
  background-color: none;
  border: 2px solid white;
  color: white;
  font-size: 12px;
  padding: 7px 21px;
  letter-spacing: 2px;
}

.text:hover {
  background-color: white;
  color: black;
}

a.text {
  text-decoration: none;
  min-width: 36px;
}

.soon-text {
  background-color: none;
  border: 2px solid white;
  color: white;
  font-size: 12px;
  padding: 7px 19px;
  letter-spacing: 2px;
  cursor: default;
  text-wrap: nowrap;
  min-width: 36px;
}

.spacer {
  height: 300px;
}

.about-description-panel {
  display: flex;
  flex-direction: column;
  border: 2px solid var(--mediumGray);
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  justify-content: center;
  padding-top: 17px;
  max-height: 451px;
}

.section-description {
  line-height: 1.5;
  text-align: center;
}

.nav-link {
  color: white;
}

.navbar-darken {
  background-color: black;
}

.navbar-no-color {
  background-color: none;
}

.navbar-nav > li {
  padding-left:30px;
  padding-right:30px;
}

.navbar .navbar-nav > li > a:hover {
  color: #BF5700;
}

.app {
  background-image: url(../images/milkyway.jpg);
  background-color: #333F48;
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
  height: auto;
  max-height: 600px;
}

.nameplate {
  color: white;
  font-size: 50px;
  padding-top: 50px;
  font-family: "Lucida Console", Monaco, monospace;
  letter-spacing: 2.2px;
  word-spacing: -10px;
  text-decoration: none;
  font-style: normal;
  font-variant: normal;
  text-transform: uppercase;
  text-shadow: 1px 1px 0px #000000;
}

.subplate-text {
  color: white;
  font-size: 12px;
  font-family: "Lucida Console", Monaco, monospace;
  letter-spacing: 2.2px;
  word-spacing: 0px;
  text-decoration: none;
  font-style: normal;
  font-variant: normal;
  text-transform: uppercase;
  text-shadow: 1px 1px 0px #000000;
}

.profile-photo {
  border-radius: 50%;
  border: 2px solid #000000;
  width: 250px;
  height: auto;
  margin-top: 25px;
  margin-bottom: 50px;
  filter: grayscale(100%);
}

.logo {
  width: auto;
  height: 70px;
}

.grey-container {
  width: 100%;
  padding: 50px;
  background-color: #f0eff0; /* whitesmoke */
}

.white-container {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: white; /* white */
}

.dark-slate-container {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 50px;
  background-color: var(--darkSlate);
  color: white;
}

.underline {
  text-decoration: underline;
}

.box-background {
  background-color: black;
  border-radius: 10px;
  width: 100%;
  position: relative;
}

.portfolio-item-wrapper {
  margin-top: 25px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.portfolio-item-wrapper:first-of-type {
  margin-top: 50px;
}

.portfolio-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.potato {
  width: 100%;
  height: auto;
  border-radius: 10px;
  max-height: 475px;
}

.project-details-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  text-align: center;
}

#ability-section-header {
  margin-bottom: 50px;
}

.resume-button {
  border: 2px solid #BF5700;
  background-color: none;
  color: black;
  font-size: 12px;
  padding: 7px 21px;
  letter-spacing: 2px;
  text-decoration: none;
  height: 75px;
}

.resume-button:hover {
  color: white;
  background-color: #BF5700;
}

.ability-header {
  font-weight: bold;
  text-align: left;
  margin-top: 30px;
  margin-bottom: 5px;
}

.ability-name {
  font-size: 1.0em;
  margin-bottom: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.abilities-section-wrapper {
  display: flex;
  flex-direction: column;
}

.ability-section-item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

div.ability-section-item-container:hover small {
  font-weight: bold;
  color: black;
  cursor: default;
}

div.ability-section-item-container:hover svg {
  color: #FFC000;
  border-color: #FFC000;
}

.credits {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#linkedin {
  width: 25%;
  height: auto;
}

#email {
  width: 25%;
  height: auto;
}

#copywrite {
  color: grey;
}

#contact-text {
  padding: 40px;
  padding-top: 0px;
}

.contact-container {
  margin: 0px;
}

.contact-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
};

.contact-card-text {
  text-align: center;
}

.contact-card-icon {
  margin-bottom: 20px;
}

.about-container {
  width: 100%;
  height: 100%;
  background: #fff;
  padding-bottom: 50px;
}

.main {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 50px;
  padding-right: 50px;
  gap: 50px;
  overflow: hidden;
}

.main div {
  flex: 1;
  height: 100%;
  max-height: 475px;
  max-width: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.25s ease-in;
}

.slide-item-left {
  transform: translateX(0);
  opacity: 1;
}

.slide-item-right {
  transform: translateX(0);
  opacity: 1;
}

.slide-in {
  transform: translateX(0) !important;
  opacity: 1 !important;
}

.slide-item-from-bottom {
  transform: translateY(100%);
  opacity: 0;
}

.slide-up {
  transform: translateY(0) !important;
  opacity: 1 !important;
}

.modal-header {
  border-bottom: none;
}

/* work history timeline*/
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap"); */

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#work-history {
  --color: rgba(30, 30, 30);
  --bgColor: rgba(245, 245, 245);
  min-height: 50vh;
  display: grid;
  align-content: center;
  gap: 2rem;
  padding: 2rem;
  /* font-family: "Poppins", sans-serif; */
  color: var(--color);
  background: var(--bgColor);
}

#work-history h1 {
  text-align: center;
}

#work-history ul {
  --col-gap: 2rem;
  --row-gap: 2rem;
  --line-w: 0.25rem;
  display: grid;
  grid-template-columns: var(--line-w) 1fr;
  grid-auto-columns: max-content;
  column-gap: var(--col-gap);
  list-style: none;
  width: min(60rem, 90%);
  margin-inline: auto;
}

/* line */
#work-history ul::before {
  content: "";
  grid-column: 1;
  grid-row: 1 / span 20;
  background: rgb(225, 225, 225);
  border-radius: calc(var(--line-w) / 2);
}

/* columns*/

/* row gaps */
#work-history ul li:not(:last-child) {
  margin-bottom: var(--row-gap);
}

/* card */
#work-history ul li {
  grid-column: 2;
  --inlineP: 1.5rem;
  margin-inline: var(--inlineP);
  grid-row: span 2;
  display: grid;
  grid-template-rows: min-content min-content min-content;
}

#work-history li.current-item {
  padding-left: 2px;
  padding-bottom: 2px;
  padding-right: 2px;
}

/* makes active job orange */
#work-history li.current-item:hover {
  padding-left: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  border-left: var(--orange) 2px solid;
  border-bottom: var(--orange) 2px solid;
  border-right: var(--orange) 2px solid;
}

/* makes past jobs slate */
#work-history li.past-item:hover {
  border-left: var(--slate) 2px solid;
  border-bottom: var(--slate) 2px solid;
  border-right: var(--slate) 2px solid;
}

/* date */
#work-history ul li .date {
  --dateH: 3rem;
  height: var(--dateH);
  margin-inline: calc(var(--inlineP) * -1);

  text-align: center;

  color: white;
  font-size: 0.8rem;
  font-weight: 450;

  display: grid;
  place-content: center;
  position: relative;

  border-radius: calc(var(--dateH) / 2) 0 0 calc(var(--dateH) / 2);
}
/* makes active job orange */
#work-history .date-current {
  background-color: var(--orange);
}

/* makes past jobs slate */
#work-history .date-past {
  background-color: var(--slate);
}

/* date flap */
#work-history ul li .date::before {
  content: "";
  width: var(--inlineP);
  aspect-ratio: 1;
  background-image: linear-gradient(rgba(0, 0, 0, 0.2) 100%, transparent);
  position: absolute;
  top: 100%;

  clip-path: polygon(0 0, 100% 0, 0 100%);
  right: 0;
}

/* makes current jobs orange */
#work-history ul li .date-current::before {
  background-color: var(--orange);
}

/* makes past jobs slate */
#work-history ul li .date-past::before {
  background-color: var(--slate);
}

/* circle */
#work-history ul li .date::after {
  content: "";
  position: absolute;
  width: 2rem;
  aspect-ratio: 1;
  background: var(--bgColor);
  border: 0.3rem solid #41516C;
  border-radius: 50%;
  top: 50%;

  transform: translate(50%, -50%);
  right: calc(100% + var(--col-gap) + var(--line-w) / 2);
}

/* makes current jobs orange */
#work-history ul li .date-current::after {
  border: 0.3rem solid var(--orange);
}

#work-history ul li.current-item:hover .date-current::after {
  background: var(--orange);
}

#work-history ul li.past-item:hover .date-past::after {
  background: var(--slate);
}

/* makes past jobs slate */
#work-history ul li .date-past::after {
  border: 0.3rem solid var(--slate);
}

/* title descr */
#work-history ul li .title,
#work-history ul li .descr {
  background: var(--bgColor);
  position: relative;
  padding-inline: 1.5rem;
}
#work-history ul li .title {
  overflow: hidden;
  padding-block-start: 1.5rem;
  padding-block-end: 1rem;
  font-weight: 500;
}
#work-history ul li .descr {
  padding-block-end: 1.5rem;
  font-weight: 300;
}

/* shadows */
#work-history ul li .title::before,
#work-history ul li .descr::before {
  content: "";
  position: absolute;
  width: 90%;
  height: 0.5rem;
  background: rgba(0, 0, 0, 0.5);
  left: 50%;
  border-radius: 50%;
  filter: blur(4px);
  transform: translate(-50%, 50%);
}
#work-history ul li .title::before {
  bottom: calc(100% + 0.125rem);
}

#work-history ul li .descr::before {
  z-index: -1;
  bottom: 0.25rem;
}

#work-history-subtext {
  margin-top: 100px;
  margin-bottom: 20px;
}

#resume-container {
  margin-bottom: 75px;
}

#work-history .history-timeline ul li .date:first-of-type {
  background: var(--orange);
}

.main-modal {
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0, .8);
  z-index: 1000;
  overflow-y: auto;
}

.modal-body {
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
}

.main-modal .row {
  margin-bottom: 25px;
}

.modal-button {
  border: none;
}

.history-description {
  list-style: none;
  margin-bottom: 20px;
}

.history-experience {
  margin-bottom: 10px;
};

.modal-dialog {
  position: absolute;
  background-color: #FFF;
  padding: 15px;
  z-index: 1000;
  width: 100%;
  border-radius: .5em;
}

.portfolio-icon-container {
  flex-grow: 1;
}

/* Media Breakpoints */
@media (min-width: 300px) {
  .main {
    height: 1000px;
    flex-direction: column;
  }

  .profile-item-details {
    margin-top: 25px;
    width: 100%
  }
}

@media (min-width: 600px) {
  .slide-item-left {
    transform: translateX(-150%);
    opacity: 0;
  }

  .slide-item-right {
    transform: translateX(150%);
    opacity: 0;
  }

  .section-description {
    line-height: 38px;
  }

  #work-history ul li .date {
    font-size: 1.25rem;
    font-weight: 550;
  }

  .project-details {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .box-background {
    max-width: 400px;
  }

  .portfolio-icon {
    max-width: 400px;
  }

  .app {
    height: auto;
    max-height: 900px;
  }
}

@media (min-width: 767px) {
  .subplate-text {
    font-size: 20px;
  }

  .profile-photo {
    width: 500px;
  }

  .main {
    flex-direction: column;
  }

  #about-text {
    width: 72.75%;
  }

  .portfolio-item-hover-description {
    width: 75%;
  }

  .profile-item-details {
    margin-top: 0px;
    margin-left: 15px;
    min-width: 400px;
  }

  .portfolio-icon {
    max-height: 268px;
  }

  .main-modal {
    position: fixed;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, .8);
    z-index: 1000;
    overflow-y: auto;
  }

  .modal-dialog {
    max-width: 600px;
  }

  .portfolio-item-wrapper {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    max-height: 300px;
  }
}

@media (min-width: 992px) {
  .main {
    height: 100vh;
    flex-direction: row;
  }

  #about-text {
    width: 80%;
  }
  .project-details-wrapper {
    margin-top: 0px;
    text-align: inherit;
  }

  #contact-text {
    padding: 0px 200px 50px 200px;
  }

  #work-history ul {
    grid-template-columns: 1fr var(--line-w) 1fr;
  }
  #work-history ul::before {
    grid-column: 2;
  }
  #work-history ul li:nth-child(odd) {
    grid-column: 1;
  }
  #work-history ul li:nth-child(even) {
    grid-column: 3;
  }

  /* start second card */
  #work-history ul li:nth-child(2) {
    grid-row: 2/4;
  }

  #work-history ul li:nth-child(odd) .date::before {
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    left: 0;
  }

  #work-history ul li:nth-child(odd) .date::after {
    transform: translate(-50%, -50%);
    left: calc(100% + var(--col-gap) + var(--line-w) / 2);
  }
  #work-history ul li:nth-child(odd) .date {
    border-radius: 0 calc(var(--dateH) / 2) calc(var(--dateH) / 2) 0;
  }

  .modal-dialog {
    max-width: 700px;
  }

  .about-description-panel {
    max-height: 415px;
  }

  .profile-item-details {
    margin-left: 50px;
    min-width: 500px;
  }
}

@media (min-width: 1200px) {

  .main {
    height: 80vh;
    flex-direction: row;
  }

  #about-text {
    width: 66.49%;
  }

  .modal-dialog {
    max-width: 700px;
  }

  .about-description-panel {
    max-height: 425px;
  }

  .profile-item-details {
    margin-left: 75px;
    min-width: 600px;
  }
};